import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "py-20 border-t-1 border-solid border-pureblack-20",
  "data-test": "identity--retry-verification"
}
const _hoisted_2 = { class: "mt-0 text-primary-500 uppercase tracking-widest" }
const _hoisted_3 = {
  class: "m-0",
  "data-test": "retry-countdown"
}
const _hoisted_4 = { class: "text-primary-500" }
const _hoisted_5 = { class: "m-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_app_link = _resolveComponent("app-link")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString($props.retrymessage), 1),
    _withDirectives(_createElementVNode("p", { class: "retry-sub-text verify-email-text m-0 mb-10" }, _toDisplayString($props.retrysubtext), 513), [
      [_vShow, $props.retrysubtext]
    ]),
    _withDirectives(_createElementVNode("p", _hoisted_3, [
      _cache[0] || (_cache[0] = _createTextVNode(" You can retry in ")),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.currentCount), 1),
      _cache[1] || (_cache[1] = _createTextVNode(" seconds. "))
    ], 512), [
      [_vShow, $props.countdowntime && !_ctx.removeCountdown]
    ]),
    _withDirectives(_createElementVNode("p", _hoisted_5, [
      _createVNode(_component_app_link, {
        "data-test": "resend-verification-link",
        class: "cursor-pointer text-primary-500 text-16",
        onClick: $options.retry
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.resendmessage), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ], 512), [
      [_vShow, _ctx.removeCountdown]
    ])
  ]))
}