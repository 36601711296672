
import { mapState } from 'vuex'
import RetryVerification from '@/components/identity/RetryVerification/RetryVerification.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'

export default {
  name: 'VerifyEmail',
  components: {
    PDEHeading,
    RetryVerification,
  },
  props: {
    countdowntime: {
      type: Number,
      default: 60,
      required: false,
    },
    wrapperWidth: {
      type: Number,
      default: 175,
      required: false,
    },
    textWidth: {
      type: Number,
      default: 200,
      required: false,
    },
  },
  data: function () {
    return {
      fontSize: '3',
      subHeader: '',
    }
  },
  computed: {
    ...mapState('identity', {
      forgot: state => state.forgot,
      register: state => state.register,
    }),
    shownEmail() {
      let email

      if (
        this.register.status === 'CREATED' ||
        this.register.status === 'REACTIVATE'
      ) {
        email = this.register.email
      } else {
        email = this.forgot.email
      }

      return email || ''
    },
    updateFontSize: {
      get() {
        return this.fontSize
      },
      set(newFontSize) {
        if (newFontSize >= 3) {
          this.fontSize = 3
        } else {
          // this keeps the fontsize rounded to a single decimal place.
          // if a user has an incredibly long email address it will be hard to read but that is a very edge case
          this.fontSize = Math.round(newFontSize * 10) / 10
        }
      },
    },
    mainMessage() {
      return this.$t(
        'identity.verifyEmail.message.main.' +
          (this.register.status === 'CREATED' ||
          this.register.status === 'REACTIVATE'
            ? 'register'
            : 'forgot')
      )
    },
  },
  beforeMount() {
    if (
      this.register.status !== 'CREATED' &&
      this.register.status !== 'REACTIVATE'
    ) {
      if (!this.forgot.email || this.forgot.email === '') {
        // User didn't come from the "/forgot" form
        // Redirect to "/forgot"
        this.$router.push({ path: '/forgot' })
      }
    } else {
      this.subHeader = this.$t('identity.verifyEmail.heading.sub')
    }
  },
  mounted() {
    if (this.shownEmail) {
      this.formatEmailText()
    }
  },
  methods: {
    formatEmailText() {
      const wrapWidth =
        this.$refs.emailAddressWrap.offsetWidth || this.wrapperWidth
      const textWidth =
        this.$refs.emailAddressText.offsetWidth || this.textWidth
      const newFontSize = (wrapWidth / textWidth) * this.fontSize

      this.updateFontSize = newFontSize
    },
  },
}
